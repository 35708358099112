﻿import axios from "axios";

export class HomeWinners {



    setSlidesPerView(context) {

        let newSlideCount = 0;
        let _width = parseInt(window.innerWidth);
       

        if (_width > 1120) {
            newSlideCount = 4;
        } else if (_width > 810) {
            newSlideCount = 3;
        } else if (_width > 510) {
            newSlideCount = 2;
        } else {
            newSlideCount = 1;
        }


        if (newSlideCount != context.lastSlideCount) {
            //resize;
            document.querySelector("#nationalWinnerSlider").setAttribute("slides-per-page", newSlideCount)
            context.lastSlideCount = newSlideCount;
        }

    }

    render(data, target) {

        const d = document;
        var shell = d.querySelector(target);

        data.forEach((item) => {
            const slide = document.createElement("sl-carousel-item");
            slide.classList.add("poy-slide");
            slide.classList.add("winner-slide");
            if (item.Link) {
                const championTag = item.IsChampion ? `<div class="winner">Champion Winner</div>` : "";

                slide.innerHTML = `
                        <a href="${item.Link}">
                            <div class="winner-slide-top">
                                <img src="${item.Sport.IconBlack}" alt="${item.Sport.Name}" loading="lazy" /> <h3>${item.Sport.Name}</h3>
                            </div>
                            <div class="winner-slide-bottom">
                                <div class="winner-slide-image">
                                    ${championTag}
                                    <img src="${item.Image}" alt="${item.Name}" />
                                </div>
                                <div class="winner-slide-details">
                                    <div class="winner-slide-location-holder">
                                        <div class="winner-slide-location">
                                            ${item.City}
                                        </div>
                                    </div>
                                    <div class="winner-slide-school">
                                        ${item.Highschool}
                                    </div>
                                    <div class="spacer">&nbsp;</div>
                                    <div class="winner-slide-name">
                                        ${item.Name}
                                    </div>
                                </div>
                            </div>
                        </a>
                    `;

            } else {
                slide.innerHTML = `
                        <div>
                            <div class="winner-slide-top">
                               <img src="${item.Sport.IconBlack}" alt="${item.Sport.Name}" loading="lazy" /> <h3>${item.Sport.Name}</h3>
                            </div>
                            <div class="winner-slide-bottom">
                                <div class="winner-slide-image">
                                    <img src="${item.Image}" alt="${item.Sport.Name}" />
                                </div>
                                <div class="winner-slide-details empty">
                                    <div class="center-details">
                                        <div class="winner-slide-progress">Greatness in Progress</div><br />
                                        Gatorade National Player of the Year coming soon.
                                    </div>
                                </div>
                            </div>
                        </div>
                `;
            }
            shell.appendChild(slide);

        });

    }



    constructor(_target) {
        const API_ENDPOINT = "/api/frontenddata/GetNationalWinners";
        let target = _target;
        this.lastSlideCount = 4;

        const that = this;

        this.setSlidesPerView(this);

        window.addEventListener("resize", (e) => {
            that.setSlidesPerView(that);
        });


        axios.get(API_ENDPOINT).then((result) => {
            if (result.data) {
                that.render(result.data, target);
            }
        });


    }

}