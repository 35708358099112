﻿import './home.less'
import '../../components/videoPlayer.less'
import { HomeWinners } from "./HomeWinners.js"


var winnerSlider = new HomeWinners("#nationalWinnerSlider");
let isScrolling = false;



const init = () => {


    const POYHome = videojs('POYHome', {}, function () {




        this.on("play", (e) => {



        });
        this.on('ended', function () {
            POYHome.exitFullscreen();
            POYHome.hasStarted(false);
        });
    });

    this.elements = [
        {
            "container": document.querySelector("#POYHome"),
            "video": POYHome
        }
    ];


    const checkPosition = () => {

        for (var i = 0; i < this.elements.length; i++) {
            let video = this.elements[i].container;
            let player = this.elements[i].video;

            let position = video.getBoundingClientRect();

            let isHidden = getComputedStyle(video).display == "none";



            if (isHidden) {
                continue;
            }



            // checking whether fully visible
            let shouldPlay = false;

            if (position.top >= 0 && position.bottom <= window.innerHeight) {
                shouldPlay = true;

            } else if (position.top < window.innerHeight && position.bottom >= 0) {
                shouldPlay = true;

            }

            if (video.parentElement && video.parentElement.classList.contains("is-paused")) {
                shouldPlay = false;
            }


            if (!shouldPlay) {
                player.pause();

                player.exitFullscreen();
                player.hasStarted(false);
            }

        }
    };
    window.addEventListener('scroll', function (event) {

        // Clear our timeout throughout the scroll
        window.clearTimeout(isScrolling);

        // Set a timeout to run after scrolling ends
        isScrolling = setTimeout(function () {

            // Run the callback
            checkPosition();

        }, 66);

    }, false);
}

if (document.readyState !== 'loading') {
    init();
} else {
    document.addEventListener('DOMContentLoaded', function () {
        init();
    });
}

